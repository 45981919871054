<template>
  <div class="bordered mt16">
    <Title>人才管理</Title>
    <TotalAnalysis style="marginBottom: 0" :value="analysisNumByLevel" genre="元"></TotalAnalysis>
    <BaseEchart :option="option" style="margin-top: -10px; width: 100%; height: 400px"></BaseEchart>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import Title from '../components/Title';
import BaseEchart from '@/components/BaseEchart';
import TotalAnalysis from '../components/TotalAnalysis.vue'
export default {
  components: { Title, BaseEchart, TotalAnalysis },
  data() {
    return {
      option: undefined,
      analysisNum: 40560,
      talents : [
        { value: 13152, name: '康养服务' },
        { value: 6053, name: '人才培养' },
        { value: 15302, name: '设备' },
        { value: 6053, name: '中医药' }
      ]
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.option = {
        color: ['#1283E3', '#FF9D67', '#00FFFF', '#FBD25B'],
        tooltip: {
          trigger: 'item',
        },
        grid: {
          top: 0,
        },
        graphic: {
          elements: [
            {
              type: 'circle',
              shape: {
                r: 138,
              },
              style: {
                stroke: '#307BE3',
                lineWidth: 1,
              },
              left: 'center',
              top: 52,
            },
            {
              type: 'circle',
              shape: {
                r: 78,
              },
              style: {
                stroke: '#307BE3',
                lineWidth: 1,
              },
              left: 'center',
              top: 110,
            },
            {
              type: 'image',
              style: {
                image: require('../assets/pie-bg1.png'),
                width: 128,
                height: 128,
              },
              left: 'center',
              top: 125,
            },
          ],
        },
        series: [
          {
            type: 'pie',
            top: -20,
            left: 'center',
            width: 260,
            radius: ['80%', '100%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 20,
              borderColor: '#020C1D',
              borderWidth: 3,
            },
            labelLine: {
							length: 20,
							length2: 60,
						},
						label: {
							alignTo: 'edge',
							formatter: '{name|{b}}\n{value|{c}}',
							minMargin: 5,
							edgeDistance: 20,
							margin:'-60',
							lineHeight: 15,
							rich: {
								name: {
									fontSize: 16,
								},
								value: {
									fontSize: 13,
									color: '#fff',
								},
							},
						},
            data: this.talents
          },
        ],
      };
    },
  },
  computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
    analysisNumByLevel(){
      return Math.floor(this.analysisNum / (this.mapLevel + 1))
    }
	},
  watch: {
    mapLevel: {
      handler() {
        this.talents = this.talents.map(s => {
          return {
            ...s,
            value: Math.floor(s.value / (this.mapLevel + 1))
          }
        })
        this.init();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
</style>