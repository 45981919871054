<template>
  <div class="bordered mt16">
    <Title>企业TOP7</Title>
    <div class="m_xnavs">
      <em :class="navIdx == 0 ? 'on' : ''" @click="navFun(0)">设备</em>
      <em :class="navIdx == 1 ? 'on' : ''" @click="navFun(1)">中医药</em>
      <em :class="navIdx == 2 ? 'on' : ''" @click="navFun(2)">康养服务</em>
      <em :class="navIdx == 3 ? 'on' : ''" @click="navFun(3)">人才培养</em>
    </div>
    <div class="m_xweath">
      <div class="rows" v-for="(v, i) in mockData">
        <div class="icon">{{ i+1 }}</div>
        <div class="m_xare">
          <h4>{{v.name}}</h4>
          <div class="bg"><i :style="{width: v.value + '%'}"></i></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Title from '../components/Title.vue'
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';
export default {
  components: {Title, countTo},
  data() {
    return {
      CountAnimationDuration,
      mockData: [
				{ name: '黑龙江省XXXXXXX企业', value: 95, num: 95 },
				{ name: '吉林省XXXXXXXX企业', value: 88, num: 88 },
				{ name: '浙江省XXXX企业', value: 80, num: 80 },
				{ name: '辽宁省XXXXXX企业', value: 72, num: 72 },
				{ name: '江苏省XXXXXX企业', value: 68, num: 68 },
				{ name: '内蒙古XXXXXX企业', value: 59, num: 59 },
				{ name: '辽宁省XXXXXX企业', value: 40, num: 40 },
			],
      navIdx: 0
    }
  },
  computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo'])
	},
  methods: {
    navFun(i) {
      this.navIdx = i
      this.mockData.map(v => {
        v.value = v.num - 5*(i+1)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.m_xnavs{
  font-size: 14px;
  color:#fff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #213861;
  cursor: pointer;
  em{
    font-style: normal;
    font-weight: bold;
    width: 25%;
    line-height: 32px;
    height: 32px;
    text-align: center;
    position: relative;
  }
  .on{
    color:#00F0FF;
  }
  em:before{
    content: '';
    position: absolute;
    width: 1px;
    height: 12px;
    background: #213861;
    left: 0;
    top:50%;
    margin-top: -6px;
  }
  em:nth-child(1):before{
    width: 0;
  }
}
.m_xweath{
  padding:20px 18px 0;
  .rows{
    overflow: hidden;
    margin-bottom: 23px;
  }
  .icon{
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #102E5D;
    float: left;
    font-size: 20px;
    color:#fff;
    margin-top: 2px;
  }
}
.m_xare{
  margin-left: 40px;
}
.m_xare h4{
  display: flex;
  align-items: center;
  font-size: 14px;
  color:#fff;
  font-weight: normal;
}
.m_xare .bg{
  background: #0B3172;
  margin-top: 5px;
  i{
    display: block;
    height: 9px;
    background: #4BB1FF;
    opacity: 0.89;  
  }
}
.m_xweath .rows:nth-child(1){
  .icon, .m_xare .bg i{
    background: #FFB356;
  }
}
.m_xweath .rows:nth-child(2){
  .icon, .m_xare .bg i{
    background: #2B896E;
  }
}
.m_xweath .rows:nth-child(3){
  .icon, .m_xare .bg i{
    background: #454BCD;
  }
}
</style>
